.events-home-container-div {
    height: 650px;
    width: 100%;
    background-color: #221A2A;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0 0 0;
}

.events-home-container-div-title {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.events-home-container-div-title > p {
    color: white;
    font-size: 36px;
    font-weight: bold;
}

.events-home-container-div-cards {
    min-width: 100%;
    height: 110%;
    padding: 25px;
    overflow-x: auto;
}

.react-horizontal-scrolling-menu--wrapper {
    position: relative;
}

@media screen and (max-width: 960px) {

    .events-home-container-div-cards {
        padding: 25px 0 25px 0
    }

    .events-home-container-div {
        padding: 30px 0 20px 0;
        height: fit-content;
    }

    .events-home-container-div-title {
        text-align: center;
    }

    .events-home-container-div-title > p {
        font-size: 30px;
    }
}