.user-auth-dialog-content {
    height: fit-content;
    min-height: 200px;
    width: 500px;
    background-color: var(--purple);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
}

.user-auth-text {
    color: white;
}

.user-auth-code-div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.hidden-input {
    opacity: 0;
    pointer-events: none;
    height: 0;
    width: 0;
}

.user-auth-new-code {
    text-align: center;
    color: white;
    margin-top: 20px;
    font-size: 12px;
    cursor: pointer;
    width: fit-content;
}

.user-auth-new-code:hover {
    text-decoration: underline;
    color: var(--base2)
}

.event-dialog-close-icon {
    position: absolute;
    color: white;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
