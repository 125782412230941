.MuiAccordion-root {
    background-color: transparent;
    border-top: 1px solid rgba(241, 238, 244, 0.3);
    /* border-bottom: 1px solid rgba(241, 238, 244, 0.3); */
    transition: all 0.2s ease-out;
}

.MuiAccordion-root:hover {
    border-top-color: white;
}

.MuiAccordion-root:hover .MuiAccordionSummary-expandIconWrapper > svg {
    fill: white;
}

.MuiAccordion-root:hover .MuiAccordionSummary-content {
    color: white;
}

.MuiAccordionSummary-content {
    color: rgba(241, 238, 244, 0.3);
    height: fit-content;
    min-height: 40px;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.2s ease-out;
}

.MuiAccordionSummary-content.Mui-expanded {
    color: white
}

.MuiAccordionSummary-expandIconWrapper > svg {
    fill: rgba(241, 238, 244, 0.3);
}

.MuiAccordionDetails-root {
    color: white;
    margin-left: 20px;
    font-size: 16px;
    font-weight: 400;
    white-space: pre-wrap;
}


@media screen and (max-width: 960px) {

    .MuiAccordionSummary-content {
        font-size: 18px;
        font-weight: 600;
        transition: all 0.2s ease-out;
    }

    .MuiAccordionDetails-root {
        margin-left: -10px;
    }
}