.navbar-main {
    width: 100vw;
    height: 125px;
    display: flex;
    /* background: linear-gradient(90deg, var(--pink), var(--orange)); */
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 999;
}

.top-navbar-div {
    height: 40px;
    width: 100vw;
    position: sticky;
    z-index: 999;
    top: 0;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 50px;
}

.navbar-logo {
    height: 100%;
    /* width: 20%;
    min-width: 245px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 28px;
}

.logo {
    transform: scale(0.9);
    transition: all 0.3s ease-in-out;
    margin-bottom: -5px;
}

.logo:hover {
    transform: scale(0.95);
    transition: all 0.3s ease-in-out;
}

.navbar-tabs {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    margin-left: 2vw;
}

.navbar-main-links {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-container {
    height: 100%;
    /* width: 80%; */
    flex: 1 1;
    display: flex;
    justify-content: right;
    align-items: center;
}

.navbar-tab, .navbar-tab-active {
    text-decoration: none;
    color: black;
    font-size: 14px;
    font-weight: 300;
    width: max-content;
    text-align: center;
    margin: 0 2vw;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.navbar-tab-active {
    border-image: linear-gradient(90deg,var(--base1) 16.67%,var(--base2) 95.31%) 1;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    transition: all 0.3s ease-in-out;
}

.navbar-tab:hover {
    border-image: linear-gradient(90deg,var(--base1) 16.67%,var(--base2) 95.31%) 1;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    transition: all 0.3s ease-in-out;
}

/* .navbar-tab > .bottom-border {
    box-shadow: 0px 0px 2px 0px rgb(0, 0, 0);
    transition: all 0.3s ease-in-out;
} */

/* .bottom-border {
    width: 30px;
} */

/* .navbar-tab-active > .bottom-border {
    box-shadow: 0px 6px 0px 1px rgb(0, 0, 0);
    transition: all 0.3s ease-in-out;
}

.navbar-tab:hover > .bottom-border {
    box-shadow: 0px 6px 0px 1px rgb(0, 0, 0);
    transition: all 0.3s ease-in-out;
} */

.navbar-tab-active > p,
.navbar-tab:hover > p {
    text-shadow: 0 0 0.5px black;
}

.navbar-login-div > div > .login-text:hover {
    cursor: pointer;
    text-shadow: 0 0 0.5px black;
}

.navbar-login-div {
    display: flex;
    align-items: center;
    height: 25px;
    width: max-content;
    text-align:center;
    margin-right: 50px;
}

.navbar-login-div > div {
    display: flex;
}

.navbar-login-div > div > p {
    text-decoration: none;
    color: black;
    font-size: 14px;
    font-weight: 300;
}

.navbar-divider {
    background-color: white;
    height: 55% !important;
    margin: 0 15px !important;
}

.navbar-divider-mobile {
    display: none;
}

.menu-icon {
    display: none
}

.loggedin-user:hover {
    font-weight: 500;
    cursor: pointer;
}


.login-svg {
    margin-right: 10px;
}

.tp-logo {
    height: 17px;
    cursor: pointer;
}

.world-icon {
    height: 20px;
    width: 20px;
    /* transition: all 0.5s ease-in-out; */
    animation: worldspin 60s infinite linear;
}

.presented-by {
    height: 100%;
    display: flex;
    align-items: center;
}

.presented-by > p {
    color: white;
    font-size: 11px;
    margin-right: 10px;
    width: 80px;
}

.global-website {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

/* .global-website:hover > .world-icon {
    transform: rotate(180deg);
} */

.global-website > p {
    color: white;
    margin-left: 10px;
    font-size: 12px;
}

@keyframes worldspin {
    0% { transform: rotate(0deg);}
    100% { transform: rotate(360deg);}
}

@media screen and (max-width: 960px) {

    .presented-by > p { /* ios workarround */
        width: 80px;
        text-align: right;
    }

    .menu-icon {
        display: block;
        top: 0;
        right: 0;
        transform: scale(1.5);
    }

    .menu-icon > button {
        color: black;
    }

    .navbar-container {
        margin-right: 35px;
        width: 40px;
        flex: none
    }

    .navbar-main {
        height: 101px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        align-items: center;
        height: 65px;
    }

    .logo {
        transform: scale(1);
    }

    .navbar-logo {
        min-width: 0px;
        width: 250px;
        margin-left: 25px;
    }

    .navbar-tabs {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        position: absolute;
        top: 65px;
        left: -100%;
        opacity: 1;
        background-color: white;
        transition: all 0.5s ease;
        height: 180px; /* ios has problems with fit-content --" */
    }

    .navbar-tabs.active {
        background: rgb(255, 255, 255);
        left: 0;
        top: 65px;
        opacity: 1;
        margin: 0;
        padding-bottom: 20px;
        transition: all 0.5s ease;
        z-index: 1;
        justify-content: flex-start;
        background-color: white;
        height: 180px; /* ios has problems with fit-content --" */
    }

    .navbar-tab, .navbar-tab-active {
        margin: 5px 0;
    }

    .bottom-border {
        display: none;
    }

    .navbar-divider-mobile {
        display: block;
        background-color: white;
        width: 50%;
        margin: 5px 0px 10px 0px !important;
    }

    .logo:hover {
        transform: scale(1);
    }

    .top-navbar-div {
        padding-right: 0;
        justify-content: center;
    }

    .navbar-main-links {
        flex-direction: column;
    }

    .navbar-login-div {
        margin: 0;
    }

    .navbar-login-div > div > p {
        font-size: 15px;
    }

    .navbar-tab, .navbar-tab-active {
        font-size: 15px;
    }

}