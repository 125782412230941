.episode-page-top-video {
    position: relative;
    padding-top: 40%;
    width: 100%;
    overflow: hidden;
    background-color: black;
    transition: all 0.3s ease-in-out;
}

.video-iframe {
    width: 100%;
    height: 0;
    position: relative;
    transition: all 1s ease-in-out;
    animation: video-rise 1s ease-in-out forwards;
}

.episode-video {
    position: absolute;
    top: 0;
    left: 0;
}

.episode-page-bottom-section {
    width: 100%;
    height: fit-content;
    min-height: 600px;
    background-color: #221A2A;
    display: flex;
}

.bottom-section-episode-speakers {
    width: 50%;
    height: fit-content;
    padding: 50px 30px;
}

.play-icon {
    transform: scale(3);
    transition: all 0.3s ease-in-out
}

.play-icon:hover {
    transform: scale(3.5)
}

.episode-speakers-cards {
    display: flex;
    margin-top: 50px;
    flex-wrap: wrap;
    justify-content: flex-start;
    user-select: none;
}

.episode-speakers-cards>div {
    margin: 10px;
    display: flex;
    align-items: center;
    min-width: 226px;
    height: 65px;
    width: 39%;
}

.episode-speakers-avatar {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
}

.episode-speakers-avatar:hover {
    transform: scale(1.2);
}

.video-iframe-no-video {
    width: 100%;
    height: 0;
    position: relative;
    transition: all 1s ease-in-out;
    background: url('../../assets/placeholder_assets/no-video.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: black;
    animation: video-rise 1s ease-in-out forwards;
}

@keyframes video-rise {
    0% {
        padding-bottom: 0
    }

    100% {
        padding-bottom: 44%
    }
}


@media screen and (max-width: 1400px) {
    .episode-page-top-video {
        padding-top: 56.25%;
        transition: all 0.3s ease-in-out
    }

    .video-iframe {
        padding-bottom: 56.25%;
        transition: all 1s ease-in-out
    }

}

@media screen and (max-width: 960px) {

    .episode-page-bottom-section {
        flex-direction: column;
        min-height: auto;
    }

    .bottom-section-episode-speakers {
        min-height: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0
    }

    .episode-speakers-cards {
        align-items: center;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .episode-speakers-cards>div {
        margin: 10px 5px;
        height: 65px;
        max-width: 300px;
        width: 75%;
        display: flex;
        /* justify-content: center; */
    }

}