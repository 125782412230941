.serie-text-description {
    font-size: 18px;
    font-weight: 300;
    color: white;
    padding: 20px 50px;
    text-align: center;
}

.series-page-bottom-section {
    width: 100%;
    height: fit-content;
    min-height: 600px;
    background-color: var(--purple);
    display: flex;
    flex-direction: column;
    padding: 0 50px 50px;
}

.serie-events-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.series-event-container-title {
    text-align: center;
    color:  white;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}


@media screen and (max-width: 960px) {
    .serie-text-description {
        padding: 0px;
    }

    .series-page-bottom-section {
        padding: 20px 0px;
    }
}