.footer-container {
    position: static;
    bottom: 0;
    height: 130px;
    width: 100vw;
    display: flex;
    background-color: black;
    display: flex;
    flex-direction: column;
}

.footer-content {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 35px;
}

.footer-content-top {
    width: 100%;
    height: 60%;
    padding: 0 45px;
    display: flex;
    align-items: center;
}

.footer-links {
    color: white;
    text-decoration: underline;
    font-size: 12px;
    display: flex;
}

.footer-links > p {
    margin: 0 10px;
    cursor: pointer;
}

.footer-content-top > img {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.footer-content-top > img:hover {
    transform: scale(1.1);
}

.footer-content > p {
    color: white;
    font-size: 12px;
}

@media screen and (max-width: 960px) {
    
    .footer-content-top {
        justify-content: center;
        padding: 0;
        height: 35%;
        margin-top: 5px;
    }

    .footer-content {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0;
        height: 65%;
        text-align: center;
    }

    .footer-links {
        width: 100%;
        margin-bottom: 10px;
        justify-content: center;
    }

    .footer-content-top > img {
        margin: 0px 20px 0 10px;
        transform: scale(0.8);
    }

    .footer-content > p {
        margin: 0 15px 0 10px;
        text-align: center;
    }

    .footer-content-top > img:hover {
        transform: scale(0.8);
    }
}