.date-picker-input {
    width: fit-content;
    width: 150px;
    border: 1px solid rgb(34, 26, 42);
    border-radius: 20px;
    height: 36px;
    padding: 0px 10px 0 20px;
    color: rgb(0 0 0 / 50%);
    position: relative;
    cursor: pointer;
    user-select: none;
}

.date-picker-input.empty {
    font-size: 16px;
    line-height: 36px;
}

.date-picker-input.filled {
    font-size: 12px;
    line-height: unset;
    text-align: center;
}

.date-picker-input-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 1px solid rgb(0 0 0 / 28%);
    border-radius: 20px;
    pointer-events: none;
}

.date-picker-input:hover {
    color: #E8954E;
    border-color: #E8954E;
}

.date-picker-input:hover>.date-picker-input-inner {
    border-color: transparent;
}

.date-picker-input-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}