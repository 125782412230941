.calendar-list-main-div {
    height: calc(100vh - 165px);
    /* height: 100vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.calendar-list-container {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 40px 10px;
    gap: 5px;
    margin-bottom: 15px;
    position: relative;
    scroll-behavior: smooth;
}

.event-list-div:nth-child(odd) {
    background-color: #f5f5f7;
}

.event-list-div:nth-child(even) {
    background-color: #fcfcff;
}

.event-list-div {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 20px;
    border-radius: 20px;
    animation: cards 0.5s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.event-list-div:hover {
    padding-left: 30px;
}

.event-list-div>img {
    width: 200px;
    height: 100px;
    object-fit: cover;
    border-radius: 20px;
}

.event-list-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.event-list-title {
    font-size: 20px;
    font-weight: 700;
    transition: all 0.2s ease-in-out;
    border-bottom: 1px solid var(--pink);
    width: fit-content;
}

.event-list-title:hover {
    background-color: var(--pink);
    cursor: pointer;
    padding: 0 10px;
}

.event-list-sub-info {
    display: flex;
    gap: 20px;
    color: grey;
    font-size: 15px;
    align-items: center;
}

.sub-info-item {
    display: flex;
    gap: 5px;
    align-items: center;
    border-bottom: 1px solid transparent;
}


.event-list-chips-div {
    display: flex;
    gap: 20px;
}

.event-list-chip {
    background-color: #e6e6e8;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
}

.chip-span {
    cursor: pointer;
}

.chip-span:hover {
    border-bottom: 1px solid var(--pink);

}

.event-list-chip>p {
    color: grey;
}

.event-list-chip span {
    color: black;
}

.event-serie-link:hover {
    cursor: pointer;
    border-bottom: 1px solid var(--pink);
}

.calendar-list-container::-webkit-scrollbar {
    width: 10px;
}


@keyframes cards {
    0% {
        opacity: 0;
        transform: translateX(200px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@media screen and (max-width: 960px) {

    .calendar-list-container {
        gap: 20px;
        padding: 0 10px;
        margin-top: 50px;
    }

    .event-list-div {
        flex-direction: column;
        padding: 0 0 10px 0;
        gap: 10px;
        border-radius: 10px;
        max-width: 500px;
    }

    .event-list-div>img {
        width: 100%;
        height: 100px;
        border-radius: 10px 10px 0 0;
    }

    .event-list-title {
        text-align: center;
        border-bottom: none;
        width: 100%;
        padding: 0 10px;
        text-decoration: underline;
    }

    .event-list-chips-div {
        justify-content: space-evenly;
        padding: 0 10px;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .event-list-chip {
        text-align: center;
        width: fit-content;
    }

    .event-list-div:hover {
        padding-left: 0;
    }

    .event-list-info {
        align-items: center;
    }

    .event-list-sub-info {
        padding: 0 10px;
        flex-direction: column;
        gap: 5px;
    }

    .event-serie-link {
        text-decoration: underline;
    }

}