.breadcrumb-main-div {
    padding-left: 50px;
    color: white;
    /* height: 30px; */
    display: flex;
    align-items: center;
    background-color: #221A2A;
}

.breadcrumb-link {
    color: rgba(255, 255, 255, 0.4);
    text-decoration: none;
    text-transform: capitalize;
}

.breadcrumb-link.active {
    color: white;
    cursor: default;
    text-decoration: none;
}

.breadcrumb-link:hover {
    color: white;
}

.breadcrumb-main-div > nav {
    color: rgba(255, 255, 255, 0.4);
}

@media screen and (max-width: 960px) {
    .breadcrumb-main-div {
        padding-left: 25px;
        display: none;
    }
}