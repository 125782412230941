.speaker-card-main-div {
    margin: 20px;
    display: flex;
    align-items: center;
    height: 65px;
    width: 270px;
}

.speaker-name-text {
    font-size: 14px;
    font-weight: 600;
    color: white
}

.speaker-job-text {
    font-size: 12px;
    font-weight: 400;
    max-height: 35px;
    overflow: hidden;
    color: white;
    overflow-y: auto;
}

.speaker-avatar {
    margin-right: 10px;
    width: 60px !important;
    height: 60px !important;
}

.web-icon {
    fill: white !important;
    font-size: 18px !important;
    cursor: pointer !important;
    transition: all 0.3s ease-in-out !important
}

.web-icon:hover {
    transform: rotate(-25deg);
}

.speaker-card-main-div img {
    transition: all 0.2s ease-in-out
}

.speaker-card-main-div:hover img {
    transform: scale(1.2);
    transition: all 0.2s ease-in-out
}

@media screen and (max-width: 1300px) { 
    .speakers-name-text {
        text-align: center;
    }
}

@media screen and (max-width: 960px) { 
    .speaker-card-main-div  {
        margin: 20px 5px;
        height: 65px;
        max-width: 300px;
        width: 75%;
    }
}