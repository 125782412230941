.myl-episodes-div {
    height: 450px;
    width: 100%;
    background: linear-gradient(90deg, var(--pink), var(--orange));
    padding: 50px 0 0px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.myl-episodes-cards {
    min-width: 100%;
    padding-top: 30px;
    overflow-x: auto;
    overflow-y: hidden;
}

.myl-episodes-title > p {
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    color: white;
}

@media screen and (max-width: 960px) {
    .myl-episodes-title {
        text-align: center;
    }

    .myl-episodes-cards {
        padding: 10px 0 0;
        /* min-height: 300px; */
    }

    .myl-episodes-div {
        padding: 20px 0 0;
        height: fit-content;
    }

    .myl-episodes-title > p {
        font-size: 30px;
    }
}