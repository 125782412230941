.about-main-div {
    min-height: calc(100vh - 295px);
    animation: fadeIn 0.5s ease-in-out;
}

.section-div {
    /* height: 630px; */
    height: fit-content;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-text-image-right {
    width: 30%;
    min-width: 400px;
    margin-right: 8vw;
}

.section-text-image-left {
    width: 30%;
    min-width: 400px;
    margin-left: 8vw;
}

.section-text-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 25px;
}

.section-text-content {
    font-size: 16px;
    font-weight: 400;
    white-space: pre-wrap;
}

.section-img {
    width: 315px;
    height: 375px;
}

.section-text {
    width: 80%;
    max-width: 1000px;
    margin-right: 0;
}

.about-image {
    width: 320px;
    height: 380px;
    object-fit: cover;
    /* object-position: -450px 0px; */
}

.text-link {
    text-decoration: underline;
    color: cornflowerblue;
    cursor: pointer;
}

@media screen and (max-width: 960px) {
    .section-div {
        flex-direction: column !important;
        height: fit-content;
        padding-bottom: 30px;
        padding-top: 15px;
    }

    .section-text-image-right,
    .section-text-image-left {
        text-align: center;
        min-width: 100%;
        width: 100%;
        padding: 35px 20px;
        margin: 0 !important;
    }

    .section-text {
        padding: 35px 20px;
        margin: 0 !important;
        width: 100%;
    }
}