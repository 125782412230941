.private-info-dialog-content {
    background-color: var(--purple);
    min-height: 200px;
    min-width: 600px;
    max-height: 800px;
    overflow-y: auto;
}

.private-info-dialog-content::-webkit-scrollbar {
    width: 10px;
}

.private-info-event-info {
    color: white;
}

.private-info-event-info > p {
    font-size: 26px;
    width: 100%;
    font-weight: 700;
}

.private-info-episode-info {
    color: white;
    margin-top: 15px;
}

.private-info-episode-info > p {
    font-size: 26px;
    width: 100%;
    font-weight: 700;
    margin-bottom: -15px;
}

.episode-display-div {
    padding-left: 20px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.event-display-div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.values-chip {
    background-color: var(--base1) !important;
    width: 150px;
}

.values-chip > * {
    color: white !important;
}

.display-div-title {
    color: var(--orange);
    font-weight: 700;
}

.private-info-url {
    text-decoration: underline;
    cursor: pointer;
    color: var(--pink);
}

.private-info-avatar-div {
    display: flex;
    align-items: center;
    gap: 5px;
}