.home-topics-cards-container{
    height: 650px;
    width: 100%;
    background-color: #221A2A;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: 50px 0; */
}

.home-topics-cards-title {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-topics-cards-title > p {
    color: white;
    font-size: 36px;
    font-weight: bold;
}

.home-topics-cards-div {
    min-width: 100%;
    height: 110%;
    padding: 25px;
    overflow-x: auto;
}

.react-horizontal-scrolling-menu--wrapper {
    position: relative;
}

@media screen and (max-width: 960px) {

    .home-topics-cards-div {
        padding-left: 5px;
    }

    .home-topics-cards-title > p {
        font-size: 30px;
    }
}
