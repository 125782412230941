.standard-filter-main-div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.standard-filters {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    gap: 15px;
    transition: all 0.3s ease-in-out;
}

.standard-filtered-chips {
    width: 100%;
    margin-top: 20px;
    transition: all 0.2s ease-in-out;
}

.standard-all-chips {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transition: all 0.2s ease-in-out;
}

.standard-clear-all-chip {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
}

.standard-clear-all-chip>div {
    background-color: var(--orange);
    color: white;
    transition: all 0.2s ease-in-out;
}

.standard-clear-all-chip>div>svg {
    fill: white;
    transition: all 0.2s ease-in-out;
}

.standard-clear-all-chip>div>svg:hover {
    transform: scale(1.1);
}

.standard-clear-all-chip>div:hover {
    border-radius: 10px;
}

.standard-all-chips>hr {
    margin: 0 10px;
    height: 30px;
}

.standard-all-chips>div {
    margin: 5px;
    border-color: #B14282;
    transition: all 0.2s ease-in-out;
}

.standard-all-chips>div:hover {
    border-radius: 10px;
}

.standard-all-chips>div>svg:hover {
    transform: scale(1.1);
}

.standard-all-chips>div>svg {
    transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 960px) {

    .standard-filter-main-div {
        position: absolute;
        top: 141px;
        background-color: white;
        padding: 20px 0 10px;
        z-index: 200;
        max-width: 700px;
    }

    .standard-filter-main-div.show {
        transform: translateY(-40px);
    }

    .standard-filter-main-div.hide {
        transform: translateY(-116%);
    }

    .standard-filters {
        flex-wrap: wrap;
        margin-top: 25px;
    }

    /* .standard-filters > div {
        margin-bottom: 25px;
    } */

    .standard-filtered-chips {
        margin-top: 0px;
    }
}