.home-main-div {
    height: fit-content;
    min-height: calc(100vh - 295px);
    user-select: none;
    background-color: var(--purple);
    animation: fadeIn 0.5s ease-in-out;
}

.home-top-video-div {
    height: fit-content;
    max-height: 775px;
    min-height: 200px;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #221A2A;
}


.top-image {
    height: auto;
    width: 100%;
    margin-bottom: -5px;
    object-fit: cover;
    margin-top: -10vw;
}

.top-video-info {
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 50px;
    z-index: 50;
    max-width: 600px;
}

.top-video-black-div {
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.0) 50%, rgba(0,0,0,0.9) 100%);
    transition: all 0.3s ease-in-out;
    z-index: 20;
}

.top-video-static-black-div {
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: rgba(0,0,0,0.3);
    transition: all 0.3s ease-in-out;
    z-index: 20;
}

.home-top-video-div:hover > .top-video-static-black-div {
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.top-video-buttons {
    display: flex;
    margin-top: 20px;
}

.top-video-title {
    font-size: 36px;
    font-weight: bold;
    color: white;
    text-shadow: 0px 0px 5px black ;
    margin-bottom: 5px;
}

.top-video-subtitle {
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
    color: white;
    text-shadow: 1px 1px 10px black ;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 960px) {
    .top-image {
        max-height: 350px;
        min-height: 275px;
        height: 56vw;
        margin-top: 0;
    }

    .top-video-info {
        position: initial;
        width: 100%;
        padding: 0 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: black;
        padding-top: 10px;
        max-width: unset;
    }

    .top-video-title {
        font-size: 32px;
        width: 90%;
        text-align: center;
    }

    .top-video-subtitle {
        font-size: 16px;
        text-align: center;
        max-height: 50%;
        overflow: auto;
    }

    .top-video-black-div {
        max-height: 350px;
        min-height: 275px;
        height: 56vw;
        background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.0) 50%, rgba(0,0,0,1) 100%);
        transition: none;
    }

    .top-video-static-black-div {
        background: none;
    }
}
