.signup-form-main-div {
    height: 550px;
    min-width: 600px;
    position: relative;
}

.signup-marketo-form {
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: absolute;
    background-color: #F1EEF4;;
}

.mktoFormRow {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mktoFieldDescriptor {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px 10px 10px !important
}

.mktoFieldWrap {
    display: flex;
    flex-direction: column;
}

.mktoLabel {
    font-size: 16px;
    width: 200px !important;
    margin-bottom: 10px !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
}

.mktoField {
    height: 40px;
    -webkit-appearance: none;
    width: 265px !important;
    border-radius: 5px;
    background-color: #EEEEEE;
    border: 1px solid rgba(0, 0, 0, 0.0);
    box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.2);
}

.mktoField:focus-visible {
    outline: none;
}

.mktoAsterix {
    color: black !important;
}

.mktoGutter {
    display: none
}

.mktoLogicalField {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mktoButtonWrap {
    margin-left: 0 !important;
}

.mktoButtonWrap > button {
    width: 150px;
    height: 40px;
    border-radius: 2rem;
    border: 1px solid transparent !important;
    background-color: var(--pink) !important;
    background-image: none !important;
    transition: all 0.3s ease-in-out;
}

.mktoButtonWrap > button:hover {
    background-color: var(--orange) !important;
}

.mktoLogicalField > input {
    -webkit-appearance: checkbox;
    width: 60px !important;
    height: 60px !important;
    border: 1px solid var(--pink) ;
    box-shadow: none;
}

.mktoLogicalField > label {
    text-align: center !important;
}

.marketo-form-loading-error-message {
    margin: 50px;
    text-align: center;
    white-space: pre-wrap;
}

.threedots-div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 200;
    background-color: #f1eef4;
}

@media screen and (max-width: 960px) {
    .signup-marketo-form {
        margin: 25px 0;
        padding: 0 !important;
        position: unset;
    }

    .signup-form-main-div {
        height: 100%;
        min-width: 0;
        width: 100%;
        position: unset;
    }

    .mktoFormRow {
        flex-direction: column;
    }

    .mktoFieldDescriptor {
        width: 100%;
    }
}