.watchnow-btn {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    text-indent: 5px;
    transition: all 0.3s ease-in-out;
    /* background-color: var(--pink); */
    background-image: linear-gradient(90deg,var(--base1) -20%,var(--base2) 100%);;
    background-size: 150% 200%;
    border-radius: 2rem;
    z-index: 100;
}
.watchnow-btn-higlighted {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    text-indent: 5px;
    transition: all 0.3s ease-in-out;
    /* background-color: var(--pink); */
    background-image: linear-gradient(90deg,var(--base1) -20%,var(--base2) 100%);;
    background-size: 150% 200%;
    border-radius: 2rem;
    z-index: 100;
    margin-right: 25px;
}

.watchnow-btn-higlighted:hover {
    /* background-color: var(--orange); */
    /* border-radius: 2rem; */
    background-position: 100% 100%;
}

.watchnow-btn:hover {
    background-position: 100% 100%;
}

.viewevent-btn {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    text-indent: 5px;
    transition: all 0.3s ease-in-out;
    border: 2px solid white;
    border-radius: 2rem;
    z-index: 100;
}

.viewevent-btn:hover {
    border: 2px solid transparent;
    background-color: var(--orange);
}

.combo-buttons-div {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

@media screen and (max-width: 960px) {

    .viewevent-btn {
        height: 34px !important;
        width: fit-content !important;
        font-size: 12px !important;
        padding-left: 5px !important;
        padding-right: 10px;
    }
    
    .watchnow-btn-higlighted {
        height: 34px !important;
        width: fit-content !important;
        font-size: 12px !important;
        padding-left: 5px !important;
    }
}
