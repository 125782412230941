.user-auth-otp fieldset {
    border-color: white;
}

.user-auth-otp input {
    color: white;
    font-size: 30px;
    height: 60px;
    width: 70px;
    padding: 0;
}

.user-auth-otp .MuiInputBase-root:hover fieldset,
.user-auth-otp .MuiInputBase-root:focus fieldset {
    border-color: var(--base2);
}

.user-auth-otp .MuiInputBase-root.Mui-focused fieldset{
    border-color: var(--base2);
}