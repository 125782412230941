.arrow-btn-left,
.arrow-btn-right {
    position: absolute !important;
    color: white !important;
    transform: scale(0);
    z-index: 110;
    background-color: rgba(0, 0, 0, 0.5) !important;
    transition: all 0.3s ease-in-out !important;
    opacity: 0;
}

.arrow-btn-left:hover,
.arrow-btn-right:hover {
    transform: scale(2);
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-radius: 2rem;
    transition: all 0.3s ease-in-out;
}

.arrow-btn-left {
    left: 0;
}

.arrow-btn-right {
    right: 0;
}

.arrow-btn-right.show {
    opacity: 1;
    transform: scale(1.5);
}

.arrow-btn-left.show {
    opacity: 1;
    transform: scale(1.5);
}

@media screen and (max-width: 960px) {
    .arrow-btn-right.show, .arrow-btn-left.show {
        display: none;
    }
}