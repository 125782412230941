.login-form-main-div {
    height: 450px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-marketo-form {
    -webkit-appearance: none;
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: absolute;
}

.login-text-checkbox {
    position: absolute;
    top: 150px;
    width: 65%;
    height: 175px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.login-marketo-form > .mktoFormRow {
    margin-bottom: 200px !important;
}

.login-text {
    font-size: 14px;
    font-weight: 400;
    color: #AAAAAA;
}