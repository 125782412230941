.login-dialog-content {
    /* height: 650px; */
    /* width: 700px; */
    min-width: 450px;
    background-color: #F1EEF4;
    padding: 15px 24px !important;
}

.login-dialog-content::-webkit-scrollbar {
    width: 10px;
}

.login-dialog-content::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
}

.login-dialog-content::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: rgba(255,255,255,0.5);
    background-clip: content-box;
}

.login-dialog-content::-webkit-scrollbar-thumb:hover{
    border: 3px solid transparent;
    background-color: rgba(255,255,255,0.75);
}

.close-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px;
    cursor: pointer;
    z-index: 5;
    transform: scale(1.5);
}



@media screen and (max-width: 960px) {
    .login-dialog-content {
        height: 100%;
        width: 100%;
        min-width: 0;
        padding: 5px !important;
    }

    .login-dialog-content::-webkit-scrollbar {
        width: 0;
    }
    
    .login-dialog-content::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.0);
    }

}