.calendar-main-div {
    height: fit-content;
    min-height: calc(100vh - 295px);
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn 0.5s ease-in-out;
}

.calendar-filters {
    width: 100%;
    height: 100px;
    max-width: 1800px;
}

.calendar-timeline {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    max-width: 1800px;
    user-select: none;
    margin: 25px 0 50px 0;
}

.calendar-timeline-mobile {
    display: none;
}

@media screen and (max-width: 960px) {
    .calendar-timeline-mobile {
        display: flex;
        width: 100%;
        height: fit-content;
    }

    .calendar-timeline {
        display: none;
    }

    .calendar-main-div {
        min-height: calc(100vh - 200px);
    }
}