.allepisodes-main-div {
    height: fit-content;
    min-height: calc(100vh - 295px);
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    animation: fadeIn 0.5s ease-in-out;
}

.allepisodes-filter {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.allepisodes-cards {
    /* height: fit-content; */
    height: calc(100vh - 165px);
    overflow-y: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 25px 20px;
    transition: all 0.3s ease-in-out;
}

.filters-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    transition: all 0.3s ease-in-out;
}

.filtered-chips {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    transition: all 0.2s ease-in-out;
}

.all-chips {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
    transition: all 0.2s ease-in-out;
}

.clear-all-chip {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
}

.clear-all-chip > div {
    background-color: var(--orange);
    color: white;
    transition: all 0.2s ease-in-out;
}

.clear-all-chip > div > svg {
    fill: white;
    transition: all 0.2s ease-in-out;
}

.clear-all-chip > div > svg:hover {
    transform: scale(1.1);
}

.clear-all-chip > div:hover {
    border-radius: 10px;
}

.all-chips > hr {
    margin: 0 10px;
    height: 30px;
}

.all-chips > div {
    margin: 5px;
    border-color: #B14282;
    transition: all 0.2s ease-in-out;
}

.all-chips > div:hover {
    border-radius: 10px;
}

.all-chips > div > svg:hover {
    transform: scale(1.1);
}

.all-chips > div > svg {
    transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 960px) { 

    .filters-div {
        flex-wrap: wrap;
        margin-top: 25px;
    }

    .filters-div > div {
        margin-bottom: 25px;
    }

    .allepisodes-cards {
        padding-top: 50px;
    }

    .filtered-chips {
        margin-top: 0px;
    }
}