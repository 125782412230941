.topic-event-card-main-div {
    position: relative;
    height: 390px;
    width: 283px;
    margin: 10px 15px;
    border-radius: 6px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.45);
    transition: all 0.2s ease-in-out;
}

.topic-event-card-inner-div {
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(34, 26, 42, 0) 100%);
    border-radius: 4px;
    padding-bottom: 20px;
}

.topic-event-card-info {
    text-align: center;
    width: 95%;
    text-shadow: 0px 0px 5px black;
    margin-bottom: 25px;
}

.topic-card-info-title {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 5px;
    color: white;
}

.topic-event-card-black-div {
    position: absolute;
    border-radius: 5px;
    top: 0;
    opacity: 0.7;
    filter: brightness(0.5);
    width: 100%;
    height: 100%;
    /* background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(34, 26, 42, 0) 109.42%); */
    background-blend-mode: multiply;
    transition: all 0.3s ease-in-out;
}

.topic-event-card-main-div:hover {
    transform: scale(1.02)
}

.topic-event-card-main-div:hover > .topic-event-card-black-div {
    opacity: 0.2;
}