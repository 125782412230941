.event-page-top-image {
    height: fit-content;
    max-height: 775px;
    min-height: 200px;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: var(--purple);
}

.event-page-bottom-section {
    width: 100%;
    height: fit-content;
    min-height: 600px;
    background-color: var(--purple);
    display: flex;
}

.video-iframe-trailer {
    padding-bottom: 44%;
    width: 100%;
    height: 0;
    position: relative;
    transition: all 1s ease-in-out
}

.video-iframe-trailer.test {
    width: 100%;
    height: 500px;
    padding-bottom: 0;
    overflow-y: hidden;
    position: relative;
    transition: all 1s ease-in-out;
}

.iframe.test {
    width: 100%;
    height: 1100px;
    position: absolute;
    top: -50%;
    left: 0px;
}

.trailer-top-div {
    user-select: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 500;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 100px;
}

.bottom-section-info {
    width: 50%;
    padding: 50px 20px 0 50px
}

.bottom-section-episodes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 25px 10px;
}

.bottom-section-inner-episodes {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 685px;
}

.bottom-section-text {
    width: 100%;
    height: fit-content;
    padding: 20px 20px 20px 10px;
    margin-bottom: 20px;
}

.text-title {
    font-size: 34px;
    font-weight: bold;
    color: rgba(241, 238, 244, 1);
    margin-bottom: 20px;
    text-shadow: 0 0 5px black;
}

.text-description {
    font-size: 14px;
    font-weight: 400;
    color: rgba(241, 238, 244, 1);
    white-space: pre-line;
    margin-bottom: 30px;
    text-shadow: 1px 1px 10px black;
}

.bottom-section-speakers {
    width: 100%;
    position: relative;
    overflow-y: hidden;
    /* min-height: 530px; */
    margin-bottom: 25px;
    padding-bottom: 50px;
    transition: all 1s ease-in-out;
}

.speakers-title {
    font-size: 24px;
    font-weight: bold;
    color: rgba(241, 238, 244, 1);
    margin: 20px 10px 10px;
}

.speakers-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    user-select: none;
}

.bottom-section-episodes-wrapper {
    width: 61%;
    margin-bottom: 25px;
    padding-top: 30px;
    position: relative;
    overflow-y: hidden;
    transition: all 1s ease-in-out;
}

.show-more-btn-episodes, .show-more-btn-speakers {
    position: absolute;
    user-select: none;
    cursor: pointer;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
    height: 50px;
    color: white;
    font-size: 14px;
    font-weight: 300;
    background-color: var(--purple);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
}

.event-page-actions-div {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.show-more-btn-episodes > p, .show-more-btn-speakers > p {
    margin-right: 10px;
}

.show-more-btn-episodes:hover > img, .show-more-btn-speakers:hover > img {
    height: 10px;
}

.episodes-arrow-up {
    height: 7px;
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
}

.episodes-arrow-down {
    height: 7px;
    transition: all 0.3s ease-in-out;
}

.top-video-title-event {
    font-size: 36px;
    font-weight: 700;
    color: white;
    width: 50%;
    max-width: 600px;
}

.top-video-subtitle-event {
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
    color: white;
    width: 50%;
}

.show-more-btn-episodes.hide,
.show-more-btn-speakers.hide {
    display: none;
}

.event-page-amenities-div {
    color: white;
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
}

.event-page-chips-div {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 35px;
    flex-wrap: wrap;
}

.event-page-chips-div span:hover {
    border-bottom: none;
    cursor: default;
}

.event-page-no-episodes {
    color: white;
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.event-page-no-episodes > p {
    text-align: center;
}

.event-page-no-episodes span {
    text-decoration: underline;
    cursor: pointer;
}

.event-page-no-episodes span:hover {
    color: var(--base2);
}

@media screen and (max-width: 1480px) { 
    .speakers-cards {
        justify-content: center;
    }

    .speakers-title {
        text-align: center;
    }
}

@media screen and (max-width: 1287px) { 
    .bottom-section-inner-episodes {
        width: 100%;
        justify-content: center;
    }
}

@media screen and (max-width: 1400px) { 
    .video-iframe-trailer {
        padding-bottom: 56.25%;
        transition: all 1s ease-in-out
    }
}

@media screen and (max-width: 960px) { 

    .event-page-bottom-section {
        flex-direction: column;
    }

    .bottom-section-info {
        width: 100%;
        padding: 10px;
    }

    .bottom-section-speakers {
        min-height: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .bottom-section-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
    }

    .text-description {
        text-align: center;
    }

    .speakers-cards {
        align-items: center;
        width: 100%;
        justify-content: center;
    }

    .bottom-section-episodes-wrapper {
        width: 100%;
        padding-top: 0;
    }

    .bottom-section-speakers {
        margin-bottom: 0;
        padding-bottom: 10px;
    }

    .show-more-btn-speakers {
        bottom: -5px
    }

    .video-iframe-trailer.test {
        width: 100%;
        height: fit-content;
        padding-bottom: 0;
        overflow-y: hidden;
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        transition: all 1s ease-in-out;
    }
    
    .iframe.test {
        width: 100%;
        max-height: 300px;
        min-height: 200px;
        height: 56vw;
        position: initial;
        min-width: 350px;
    }

    .trailer-top-div {
        padding: 10px;
        justify-content: center;
        align-items: center;
    }

    .top-video-title-event {
        width: 100%;
        text-align: center;
        font-size: 18px;
    }
    
    .top-video-subtitle-event {
        width: 100%;
        text-align: center;
        font-size: 16px;
    }

    .bottom-section-episodes {
        padding-top: 10px;
        padding-bottom: 20px;
    }

    .text-title {
        font-size: 24px;
        text-align: center;
    }

    .event-page-chips-div {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .event-page-amenities-div {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 30px;
    }

    .event-page-actions-div {
        justify-content: center;
    }
}
