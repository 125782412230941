.confirmation-dialog-content {
    background-color: var(--purple);
    height: 400px;
    width: 500px;
    display: flex;
    justify-content: center;
}

.confirmation-dialog-inner-content {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.confirmation-title {
    font-size: 32px;
    font-weight: 600;
    color: #F1EEF4;
    text-align: center;
}

.confirmation-subtitle {
    font-size: 20px;
    font-weight: 300;
    color: #F1EEF4;
    text-align: center;
    white-space: pre-line;
}

.confirmation-btn {
    width: 195px;
    height: 40px;
    background-color: var(--pink);
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 50px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.confirmation-btn:hover {
    background-color: var(--orange);
}

@media screen and (max-width: 960px) {

    .confirmation-dialog-content {
        width: 100%;
        height: 400px;
    }

    .confirmation-dialog-inner-content {
        width: 100%;
    }
}