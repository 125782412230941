.featured-episodes-main-div {
    height: 450px;
    width: 100%;
    /* background: linear-gradient(90deg, var(--pink), var(--orange)); */
    background-color: white;
    padding: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.featured-episodes-title > p {
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    color: black;
}

.featured-episodes-cards {
    min-width: 100%;
    padding-top: 30px;
    overflow-x: auto;
}

.react-horizontal-scrolling-menu--wrapper {
    position: relative;
}

@media screen and (max-width: 960px) {

    .featured-episodes-title > p {
        font-size: 30px;
    }

    .featured-episodes-main-div {
        height: fit-content;
        padding: 30px 0 0px 0
    }

    .featured-episodes-title {
        text-align: center;
    }

    .featured-episodes-cards {
        padding: 20px 0 20px 0;
    }
}