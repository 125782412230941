.event-filter-main-div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;
}

.event-filters {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-filters-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    gap: 15px;
    transition: all 0.3s ease-in-out; 
}

.event-filtered-chips {
    width: 100%;
    margin-top: 20px;
    transition: all 0.2s ease-in-out;
}

.event-all-chips {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transition: all 0.2s ease-in-out;
}

.event-clear-all-chip {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
}
.event-clear-all-chip > div {
    background-color: var(--orange);
    color: white;
    transition: all 0.2s ease-in-out;
}

.event-clear-all-chip > div > svg {
    fill: white;
    transition: all 0.2s ease-in-out;
}

.event-clear-all-chip > div > svg:hover {
    transform: scale(1.1);
}

.event-clear-all-chip > div:hover {
    border-radius: 10px;
}

.event-all-chips > hr {
    margin: 0 10px;
    height: 30px;
}

.event-all-chips > div {
    margin: 5px;
    border-color: #B14282;
    transition: all 0.2s ease-in-out;
}

.event-all-chips > div:hover {
    border-radius: 10px;
}

.event-all-chips > div > svg:hover {
    transform: scale(1.1);
}

.event-all-chips > div > svg {
    transition: all 0.2s ease-in-out;
}

.filters-show-button {
    display: none;
}

@media screen and (max-width: 960px) { 

    .filters-show-button {
        display: flex;
        grid-gap: 5px;
        gap: 5px;
        align-items: center;
        position: absolute;
        bottom: -40px;
        width: 100%;
        background: white;
        justify-content: center;
        padding: 10px;
        box-shadow: none;
    }

    .filters-show-button.active {
        box-shadow: 0px 3px 4px -1px rgba(0,0,0,0.5);
    }

    .event-filter-main-div {
        position: absolute;
        top: 141px;
        background-color: white;
        padding: 20px 0 10px;
        z-index: 20; 
        max-width: 700px;
    }

    .event-filter-main-div.show {
        transform: translateY(-40px);
    }

    .event-filter-main-div.hide {
        transform: translateY(-116%);
    }

    .event-filters-wrapper {
        flex-wrap: wrap;
        gap: 5px;
    }

    .event-filtered-chips {
        margin-top: 10px;
    }
}