.event-card-main-div {
    position: relative;
    height: 390px;
    width: 283px;
    margin: 10px 20px;
    border-radius: 6px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.45);
    transition: all 0.2s ease-in-out;
}

.event-card-info {
    text-align: center;
    z-index: 2;
    width: 80%;
    text-shadow: 0px 0px 5px black;
}

.event-card-button {
    z-index: 2;
}

.event-card-private {
    height: 27.21px;
    width: 100%;
    /* background-color: var(--pink); */
    background-image: linear-gradient(90deg,var(--base1) 0%,var(--base2) 100%);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    line-height: 27.21px;
    color: white;
    font-size: 13px;
    font-weight: 400;
}

.event-card-public {
    height: 27.21px;
    width: 100%;
}

.event-card-info-title {
    font-weight: bold;
    color: white;
    font-size: 28px;
    margin-bottom: 25px;
}

.event-card-black-div {
    position: absolute;
    border-radius: 5px;
    top: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(34, 26, 42, 0) 109.42%);
    background-blend-mode: multiply;
    transition: all 0.3s ease-in-out;
}

.event-card-pink-div {
    position: absolute;
    top: 0;
    border-radius: 5px;
    opacity: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, var(--pink) 0%, rgba(177, 66, 130, 0) 109.42%);
    background-blend-mode: multiply;
    transition: all 0.3s ease-in-out;
}

.event-card-main-div:hover {
    transform: scale(1.02)
}

.event-card-main-div:hover > .event-card-black-div {
    opacity: 0.8;
}

.event-card-main-div:hover > .event-card-pink-div {
    opacity: 0.7;
}