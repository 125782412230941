* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans', sans-serif; 
    /* overflow-x: hidden; */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
  
::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

::-webkit-scrollbar-track:hover {
  background-color: rgba(0,0,0,0.2);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: #7A2180;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover{
  background-color: #E40276;
}
  

  :root {
    --base1:#7A2180;
    --base2: #E40276;
    --yellow: #f5d001;
    --grey: #e1e1e1;
    --black: #000000;
    --purple: #221A2A;
    --text-grey: #F1EEF4;
    /* --pink: #B14282 */
    /* --orange: #E8954E */
    --pink:#ff0082;
    --orange: #ff5c00;
  }

