.contact-form-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.form-container {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.input {
    width: 100%;
    height: 45px;
    background-color: var(--grey);
    border-radius: 5px;
    text-indent: 10px;
    font-size: 16px;
    outline: none;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
}

.input-error {
    width: 100%;
    height: 45px;
    background-color: var(--grey);
    font-size: 16px;
    border-radius: 5px;
    text-indent: 10px;
    outline: none;
    border: 1px solid red;
    transition: all 0.3s ease-in-out;
    animation: error-border 3s infinite ease;
}

.input::placeholder, .input-error::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: rgba(113, 86, 140, 0.3);
}

.input:hover, .input-error:hover {
    background-color: #d8d8d8;
}

.label-title {
    font-style: 16px;
    font-weight: 600;
    color: var(--black);
    margin-bottom: 5px;
}

.input-group {
    margin-bottom: 25px;
    width: 100%;
}

.text-area {
    width: 100%;
    resize: vertical;
    padding-top: 5px;
    min-height: 100px;
    max-height: 300px;
    font-size: 16px;
    outline: none;
    background-color: var(--grey);
    border-radius: 5px;
    text-indent: 10px;
    border: 1px solid transparent
}

.text-area-error {
    width: 100%;
    resize: vertical;
    min-height: 100px;
    max-height: 300px;
    font-size: 16px;
    outline: none;
    background-color: var(--grey);
    border-radius: 5px;
    text-indent: 10px;
    border: 1px solid red;
    animation: error-border 3s infinite ease;
}

.text-area::placeholder, .text-area-error::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: rgba(113, 86, 140, 0.3);
}

.submit-button-div > button {
    width: 160px;
    height: 40px;
    border-radius: 2rem;
    border: 1px solid transparent;
    color: white;
    background-color: var(--pink);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-bottom: 50px;
}

.submit-button-div > button:disabled {
    background-color: #AAAAAA;
    cursor: not-allowed;
}

.submit-button-div > button:hover:enabled {
    background-color: var(--orange);
}

.contact-label > p {
    color: red;
    font-size: 14px;
    font-weight: 500;
    text-indent: 10px;
}

@keyframes error-border {
    0%, 100% {border-color: red;}
    50% {border-color: transparent;}
}

@media screen and (max-width: 960px) {

    .contact-form-container {
        width: 80%;
    }
}