.FAQ-div {
    background-color: #221A2A;
    height: fit-content;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    animation: fadeIn 0.5s ease-in-out;
}

.contact-div {
    min-height: 600px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
}

.FAQ-div > p {
    font-size: 34px;
    font-weight: bold;
    color:white;
    margin: 50px 0 30px 0;
}

.contacts-title > p:first-child {
    font-size: 34px;
    font-weight: bold;
}

.contacts-title > p:last-child {
    font-size: 18px;
    margin-top: 10px;
    font-weight: 400;
    max-width: 500px;
}

.contacts-title {
    margin: 50px 10px 30px 10px;
    text-align: center;
}

.faq-accordions {
    width: 70%;
    max-width: 1100px;
    margin-bottom: 50px;
}

@media screen and (max-width: 960px) {

    .faq-accordions {
        width: 90%;
        margin-bottom: 50px;
    }
}