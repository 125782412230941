.episode-card-main-div {
    height: 280px; 
    width: 312px;
    margin: 10px 15px;
    display: flex;
    opacity: 1;
    flex-direction: column;
    justify-content: flex-start;
    transition: all 0.2s ease-in-out;
    user-select: none;
    animation: episodeCard 0.5s ease-in-out;
    /* transform: translateY(100px); */
}

.episode-card-main-div.show {
    opacity: 1;
    transform: translateY(0);
}

.episode-card-title {
    font-size: 22px;
    font-weight: bold;
    margin-top: 10px;
}

.episode-card-footer {
    font-size: 14px;
    font-weight: 400;
}

.episode-card-content {
    position: relative;
    height: 175px;
    width: 312px;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.45);
    transition: all 0.2s ease-in-out;
}

.episode-card-content-gradient {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background-blend-mode: multiply;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.0) 100%);
}

.episode-card-content-pink-gradient {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(336.31deg, rgba(113, 86, 140, 0.5) 13.88%, rgba(177, 66, 130, 0.5) 122.55%);
    border-radius: 5px;
    background-blend-mode: multiply;
}

.episode-card-content-pink-div {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background: rgba(177, 66, 130, 0.4);
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.episode-card-content-black-div {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: all 0.2s ease-in-out;
}

.episode-description {
    color: white;
    margin: 30px 10px 10px ;
    font-size: 14px;
    height: 50%;
    font-weight: 400;
    text-align: center;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-shadow: 1px 1px black;
}

.episode-card-info-btn {
    position: absolute;
    right: 0;
    top: 0;
    color: white;
    width: 30px !important;
    height: 30px !important;
    margin: 0px;
    z-index: 100;
    transition: all 0.5s ease-in-out !important;
}

.episode-card-info-btn:hover {
    transform: rotate(180deg);
}

.episode-card-content:hover > .episode-card-content-black-div {
    opacity: 1;
}

.episode-card-content:hover > .episode-card-content-pink-div {
    opacity: 1;
}

.episode-card-content:hover {
    transform: scale(1.01);
}

.episode-card-buttons {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    margin-bottom: 15px;
    flex-direction: column;
    align-items: center;
}

.episode-card-available-soon {
    position: absolute;
    /* background-color: var(--pink); */
    background-image: linear-gradient(90deg,var(--base1) 0%,var(--base2) 100%);
    height: 30px;
    width: 100%;
    top: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    text-align: center;
    line-height: 30px;
    font-size: 13px;
    font-weight: 400;
}

.tooltip-link {
    text-decoration: none;
    color: var(--orange);
    font-size: 14px;
    font-weight: 400;
}

.tooltip-link-speaker {
    text-decoration: none;
    color: white;
    color: var(--orange);
    font-size: 14px;
    font-weight: 400;
}

.tooltip-link-speaker::after {
    content: ", ";
}

.tooltip-link-speaker:last-child::after {
    content: none;
}

.tooltip-link:hover,.tooltip-link-speaker:hover {
    font-weight: 600;
}

.toolTip-main-div {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tooltip-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 2px;
    text-align: center;
}

.tooltip-element:last-child {
    border-bottom: none;
}

.tooltip-element > p {
    font-size: 14px;
}

.mobile-episode-description-div {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    display: none;
    justify-content: center;
    align-items: flex-start;
    transition: all 0.2s ease-in-out;
}

.mobile-episode-description-div.show {
    opacity: 1;
}

.episode-card-add-btn {
    opacity: 0;
    position: absolute;
}

@keyframes episodeCard {
    0% { opacity: 0; transform: translateY(200px);}
    100% { opacity: 1; transform: translateY(0);}
}

@media screen and (max-width: 960px) {

    .episode-card-main-div {
        height: fit-content;
    }

    .mobile-episode-description-div {
        display: flex;
    }

    .episode-card-content-black-div {
        display: none;
    }

    .episode-card-add-btn {
        position: absolute;
        left: 0;
        top: 0;
        color: white;
        width: 25px !important;
        height: 25px !important;
        margin: 2px;
        opacity: 1;
        z-index: 100;
        filter: drop-shadow(1px 1px 4px rgba(0,0,0,0.6));
        transition: all 0.5s ease-in-out;
        animation: info-button infinite 3s ease-in-out;
    }

    .episode-card-add-btn.hide {
        opacity: 0;
    }

    .episode-card-title {
        font-size: 22px;
    }

    @keyframes info-button {
        0% { filter: drop-shadow(1px 1px 4px rgba(0,0,0,0.6)); transform: scale(1) }
        50% { filter: drop-shadow(1px 1px 4px rgba(0,0,0,1)); transform: scale(1.05) }
        100% { filter: drop-shadow(1px 1px 4px rgba(0,0,0,0.6)); transform: scale(1); }
      }
}