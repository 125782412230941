.not-found-main-div {
    background-color: #221A2A;
    height: fit-content;
    min-height: calc(100vh - 165px - 130px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not-found-title {
    color: white;
    font-size: 26px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}